import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Bold, Regular, MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import SharedIcon from '~shared/Icon'

const UnstyledIcon = forwardRef(function UnstyledIcon({ src, ...props }, ref) {
    return (
        <div {...props} ref={ref}>
            <SharedIcon src={src} />
        </div>
    )
})

const Icon = styled(UnstyledIcon)`
    width: 185px;

    @media ${MqTablet} {
        width: 250px;
    }
`

const Headline = styled.h1`
    font-size: 2rem;
    font-weight: ${Bold};
    line-height: 2.5rem;
    margin: 0 0 1em;
    text-align: left;

    @media ${MqTablet} {
        font-size: 3.75rem;
        line-height: 3.75rem;
    }

    @media ${MqDesktop} {
        font-size: 5.5rem;
        line-height: 6.125rem;
        text-align: center;
    }
`

const Paragraph = styled.p`
    font-size: 1rem;
    font-weight: ${Regular};
    letter-spacing: 0;
    line-height: 1.75rem;
    margin: 0;
    width: ${({ theme }) => theme.mobileWidth};

    @media ${MqTablet} {
        font-size: 1.125rem;
        line-height: 1.875rem;
        width: ${({ theme }) => theme.tabletWidth};
    }

    @media ${MqDesktop} {
        margin: 0 auto;
        font-size: 1.375rem;
        line-height: 2.25rem;
        width: ${({ theme }) => theme.desktopWidth};
    }
`

const Hero = styled.div``

Object.assign(Hero, {
    Headline,
    Icon,
    Paragraph,
})

export { Hero as default, Icon }
