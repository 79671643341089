import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Medium, MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import Link from '~shared/Link'

const TokenIcon = styled.div`
    img {
        display: block;
        width: 40px;
        height: 40px;
    }

    @media ${MqTablet} {
        img {
            width: 48px;
            height: 48px;
        }
    }
`

const Text = styled.div`
    font-size: 14px;
    line-height: 30px;
    color: #0c009a;
    white-space: nowrap;

    @media ${MqTablet} {
        font-size: 16px;
    }
`

const Title = styled.div`
    font-weight: ${Medium};
    font-size: 16px;
    line-height: 30px;
    color: #0c009a;
    white-space: nowrap;

    @media ${MqTablet} {
        font-size: 16px;
    }
`

const TextContainer = styled.div``
const UnstyledBadge = forwardRef(function UnstyledBadge(
    { className, text, href, icon, alt, title },
    ref,
) {
    return (
        <Link className={className} href={href} noDecoration noHover newTab ref={ref}>
            <TokenIcon>
                <img src={icon} alt={alt} />
            </TokenIcon>
            <TextContainer>
                <Title>{title}</Title>
                <Text>{text}</Text>
            </TextContainer>
        </Link>
    )
})

const Badge = styled(UnstyledBadge)`
    position: relative;
    background-color: white;
    box-shadow: 0px 6.30704px 50px -6.30704px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: 80px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    margin: 10px 0px;
    width: 100%;
    max-width: 640px !important;
    box-sizing: border-box;

    ${TokenIcon} + ${TextContainer} {
        margin-left: 18px;
    }

    ::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
        transition: opacity 300ms ease-in-out;
        top: 0;
        left: 0;
        opacity: 0;
    }

    :hover::after {
        opacity: 1;
    }

    :first-of-type {
        margin-top: 0 !important;
    }

    @media ${MqTablet} {
        padding: 0 24px;
        height: 104px;

        ${TokenIcon} + ${TextContainer} {
            margin-left: 18px;
        }
    }

    @media ${MqDesktop} {
        margin: 0 10px;
        /* width: 400px; */
    }
`

export default Badge
