import React, { forwardRef } from 'react'

const Icon = forwardRef(function Icon({ src, srcSet, ...props }, ref) {
    return (
        <div
            {...props}
            ref={ref}
            css={`
                padding-top: 100%;
                position: relative;

                img {
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 240%;
                }
            `}
        >
            <img src={src} srcSet={srcSet} alt="" />
        </div>
    )
})

export default Icon
