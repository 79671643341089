import React from 'react'
import Faq from '../components/Faq'
import page from '~utils/page'
import { RESOURCES } from '~shared/Nav'
import Head from '~shared/Head'

// eslint-disable-next-line import/no-unused-modules
export default page(Faq, {
    highlight: RESOURCES,
    streamrClient: true,
    headComponent: (
        <Head
            mediaDescription="Frequently Asked Questions"
            description="Frequently Asked Questions"
        />
    ),
})
