import React, { useState } from 'react'
import { Medium, MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import Container from '~shared/Container'
import Faq from '~shared/Faq'
import H from '~shared/Hero'
import Layout from '~shared/Layout'
import Spacer from '~shared/Spacer'
import { DataTokenQAs, StakeAndEarnQAs, TopQAs } from '~utils/faqs'
import urls from '~utils/urls'
import Badge from './Badge'
import DiscordIcon from './assets/discord.svg'
import MediumIcon from './assets/medium.svg'
import notFound from './assets/not_found.png'
import notFound2x from './assets/not_found@2x.png'
import TwitterIcon from './assets/twitter.svg'

const UnstyledFaqPage = (props) => {
    const [activeTab, setActiveTab] = useState('tokens')

    return (
        <Layout {...props}>
            <Spacer as={Container} head="5.87rem,5.3rem,9rem" tail="0">
                <H.Headline $appear>Frequently Asked&nbsp;Questions</H.Headline>
                <H.Paragraph $appear>
                    Below is a list of topics with the most frequently asked
                    questions&nbsp;about&nbsp;Streamr.
                </H.Paragraph>
                <HeroImg src={notFound} srcSet={`${notFound2x} 2x`} alt="" />
                <Tabs>
                    <Tab
                        type="button"
                        active={activeTab === 'general'}
                        onClick={() => setActiveTab('general')}
                    >
                        General
                    </Tab>
                    <Tab
                        type="button"
                        active={activeTab === 'tokens'}
                        onClick={() => setActiveTab('tokens')}
                    >
                        DATA tokens
                    </Tab>
                    <Tab
                        type="button"
                        active={activeTab === 'stake'}
                        onClick={() => setActiveTab('stake')}
                    >
                        Stake & Earn
                    </Tab>
                </Tabs>
                <Spacer pad head="0" tail="7rem,9rem,16.5rem">
                    <Faq key={activeTab}>
                        {activeTab === 'general' && <TopQAs />}
                        {activeTab === 'tokens' && <DataTokenQAs />}
                        {activeTab === 'stake' && <StakeAndEarnQAs />}
                    </Faq>
                </Spacer>
            </Spacer>
            <BadgeContainer>
                <Badge
                    title="Ask our community"
                    text="Get help from our Discord members"
                    href={urls.community.discord}
                    icon={DiscordIcon}
                    alt="Discord"
                />
                <Badge
                    title="Stay informed"
                    text="Get updates on project development"
                    href={urls.community.twitter}
                    icon={TwitterIcon}
                    alt="Twitter"
                />
                <Badge
                    title="Read our blog"
                    text="Follow the latest news and stories"
                    href={urls.community.medium}
                    icon={MediumIcon}
                    alt="Medium"
                />
            </BadgeContainer>
        </Layout>
    )
}

const HeroImg = styled.img``

const Tabs = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 55px;

    @media ${MqTablet} {
        margin-bottom: 80px;
    }
`

const Tab = styled.button`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: ${Medium};
    margin: 0 20px;
    background-color: ${({ active }) => (active ? '#0C009A' : 'transparent')};
    color: ${({ active }) => (active ? '#fff' : '#0C009A')};
    padding: 0 15px;
    font-size: 14px;
    border: none;
    border-radius: 100px;
    transition: opacity 180ms ease-in-out;

    &:hover {
        cursor: pointer;
        opacity: ${({ active }) => (active ? 1 : 0.7)};
    }

    @media ${MqTablet} {
        padding: 0 20px;
        font-size: 16px;
    }
`

const BadgeContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    padding-bottom: 110px;
    margin: 0 auto;
    align-items: center;

    @media ${MqTablet} {
        padding-bottom: 124px;
    }

    @media ${MqDesktop} {
        flex-direction: row;
        justify-content: space-between;
        padding: 0;
        padding-bottom: 261px;
        max-width: 1232px;
    }
`

const FaqPage = styled(UnstyledFaqPage)`
    color: #0c009a;

    ${H.Headline} {
        font-size: 2rem;
        margin-bottom: 1.2rem;
        text-align: center;
    }

    ${H.Paragraph} {
        text-align: center;
        margin: 0 auto;
        display: block;
    }

    ${HeroImg} {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 180px;
        margin-top: 130px;
        margin-bottom: 190px;
    }

    ${Faq} {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        max-width: 640px !important;
        min-height: 55rem;
    }

    @media ${MqTablet} {
        ${H.Headline} {
            font-size: 3.75rem;
            margin-bottom: 1.5rem;
        }

        ${H.Paragraph} {
            max-width: 570px;
        }

        ${HeroImg} {
            max-width: 240px;
            margin-top: 200px;
            margin-bottom: 245px;
        }
    }

    @media ${MqDesktop} {
        ${H.Headline} {
            font-size: 5.5rem;
            margin-bottom: 3.75rem;
        }

        ${H.Paragraph} {
            max-width: 706px;
        }

        ${HeroImg} {
            max-width: 270px;
            margin-top: 124px;
            margin-bottom: 155px;
        }
    }
`

export default FaqPage
