import React from 'react'
import { FaqItem, FaqMarkdown } from '~shared/Faq'

export function StakeAndEarnQAs() {
    return (
        <>
            <FaqItem openOnMount question={<>Can I particpate in the incentivized testnets?</>}>
                <p>
                    Yes! There are 5 million DATA tokens to be earned over November 2023 till
                    January 2024 - don&apos;t miss out!
                </p>
                <FaqMarkdown>
                    Checkout the testnet schedules and requirements here: [Streamr 1.0
                    Testnets](https://docs.streamr.network/streamr-testnets/testnets).
                </FaqMarkdown>
            </FaqItem>
            <FaqItem openOnMount question={<>How can I stake Streamr DATA&nbsp;tokens?</>}>
                <p>
                    To stake your DATA tokens, you must either be a Streamr node Operator or
                    delegate to one.
                </p>
                <FaqMarkdown>
                    Operators earn DATA tokens by having stake on [stream
                    Sponsorships](https://docs.streamr.network/streamr-network/incentives/stream-sponsorships).
                </FaqMarkdown>
            </FaqItem>
            <FaqItem openOnMount question={<>How can I become an earning Operator on Streamr?</>}>
                <p>
                    You&apos;ll need to create an Operator smart contract pair it with your Streamr
                    node(s), which can be run on a cloud hosted VPS, on your laptop or even on an
                    idle Raspberry&nbsp;Pi.
                </p>
                <FaqMarkdown>
                    Follow the instructions here: [How to become an
                    Operator](https://docs.streamr.network/guides/become-an-operator).
                </FaqMarkdown>
            </FaqItem>
            <FaqItem
                openOnMount
                question={<>Do I need to run Streamr&nbsp;nodes to participate in staking?</>}
            >
                <p>
                    Not at all! You can earn as a Delegator that provides passive liquidity for
                    Operators. Delegators earn by delegating their DATA tokens to Operators that in
                    exchange share a cut of their earnings.
                </p>
                <FaqMarkdown>
                    Learn about delegating here:
                    [Delegators](https://docs.streamr.network/streamr-network/network-roles/delegators).
                </FaqMarkdown>
            </FaqItem>
        </>
    )
}

export function TopQAs() {
    return (
        <>
            <FaqItem openOnMount question={<>What is the Streamr&nbsp;Network?</>}>
                <p>
                    Streamr provides the real-time data infrastructure of the decentralised web
                    (Web3). Streamr technology is a core service layer which implements a
                    vendor-neutral, real-time publish/subscribe protocol for data transmission. The
                    data travels via a global peer-to-peer network, which is scalable, robust
                    and&nbsp;permissionless.
                </p>
                <p>
                    The technology works in tandem with companion blockchains - currently Ethereum,
                    Gnosis, and Polygon - which are used for identity, security, and&nbsp;payments.
                </p>
            </FaqItem>
            <FaqItem question={<>What problem does Streamr solve?</>}>
                <p>
                    As our connected world creates and consumes more and more data, it has not been
                    possible to connect the billions of devices worldwide with real-time data via a
                    decentralised network. Meanwhile, today&apos;s existing centralised services are
                    subject to a single point of failure and vendor lock&nbsp;in.
                </p>
                <p>
                    Streamr solves these problems by delivering a low latency and secure
                    decentralised broadcast messaging protocol - via a peer to peer (P2P) network
                    architecture. Thanks to this P2P architecture - the network is fault tolerant,
                    resilient to malicious attacks and scales in line with the growth of the network
                    - for scalable many to many real time data&nbsp;pipelines.
                </p>
            </FaqItem>
            <FaqItem question={<>When was Streamr started?</>}>
                <p>
                    The Streamr project was started in 2017 by Henri&nbsp;Pihkala,
                    Nikke&nbsp;Nylund, Risto&nbsp;Karjalainen and Michael&nbsp;Malka.
                </p>
            </FaqItem>
            <FaqItem question={<>Who is building Streamr?</>}>
                <p>
                    Streamr is a distributed open-source software project with around 30 key
                    contributors around the world, including both companies and&nbsp;individuals.
                </p>
                <FaqMarkdown>
                    Key decisions in the project are made via [governance
                    voting](https://vote.streamr.network) using DATA&nbsp;tokens.
                </FaqMarkdown>
            </FaqItem>
            <FaqItem question={<>Where can I find the latest Streamr news?</>}>
                <FaqMarkdown>
                    We publish updates about Streamr, the ecosystem, node running, and other
                    happenings from the project on the [Streamr
                    blog](https://blog.streamr.network/tag/news/),
                    [Twitter](https://twitter.com/streamr),
                    [LinkedIn](https://www.linkedin.com/company/streamr-network/),
                    [YouTube](https://www.youtube.com/c/Streamr), [Telegram (announcements
                    only)](https://t.me/streamrofficial), and
                    [Discord&nbsp;community](https://discord.gg/streamr).
                </FaqMarkdown>
                <p>
                    You can also sign up for our newsletter to receive important updates directly in
                    your&nbsp;inbox.
                </p>
            </FaqItem>
        </>
    )
}

export function DataTokenQAs() {
    return (
        <>
            <FaqItem openOnMount question={<>What is the role of the Streamr DATA&nbsp;token?</>}>
                <p>
                    The DATA token is an ERC-20 token used for project governance, to incentivise
                    the Network participants, to delegate stake on Streamr nodes, and for
                    Streamr&nbsp;Hub&nbsp;payments.
                </p>
            </FaqItem>
            <FaqItem openOnMount question={<>Where can I get the DATA&nbsp;token?</>}>
                <p>
                    The DATA token can be acquired from several Centralised Exchanges (CEXs),
                    Decentralised Exchanges (DEXs), and through DEX aggregators such as Uniswap,
                    SushiSwap, and OTC. The exchange with the highest DATA/USD and DATA/BTC volume
                    is currently&nbsp;Binance.
                </p>
                <FaqMarkdown>
                    You can see a list of markets where the DATA token is listed, along with
                    compatible wallets, on the [Token
                    Migration](https://streamr.network/token-migration/)&nbsp;page.
                </FaqMarkdown>
            </FaqItem>
            <FaqItem question={<>How can I earn DATA&nbsp;tokens?</>}>
                <p>
                    In the token mechanics of the Streamr Network, tokens can be earned by running a
                    node and staking tokens on that node. The staking rewards come from a supply
                    inflation process decided via project governance. Currently, only node runners
                    can stake on their own nodes, there is no delegated staking&nbsp;yet.
                </p>
                <p>
                    In the Streamr 1.0 milestone of H2/2023, an organic market mechanism for node
                    resources is introduced. In this milestone, anyone can incentivize nodes to join
                    and support any stream on the network. This supply-and-demand-driven process
                    supplements and gradually replaces the bootstrapping incentives coming from
                    supply inflation. In Streamr 1.0, delegated staking is also introduced, where
                    token holders that don&apos;t run nodes can stake on nodes run by
                    someone&nbsp;else.
                </p>
            </FaqItem>
            <FaqItem question={<>What is Streamr “DATAcoin”?</>}>
                <p>
                    The Streamr token is often mistakenly referred to as DATAcoin and
                    Streamr&nbsp;coin.
                </p>
                <p>
                    DATAcoin was the original symbol for the Streamr token and there are still a few
                    articles floating around mentioning DATAcoin and some mentioning
                    Streamr&nbsp;coin.
                </p>
                <p>
                    However, today the token is simply called DATA or $DATA to make use of the
                    ticker functionality on platforms such as&nbsp;Twitter.
                </p>
                <FaqMarkdown>
                    Since the [DATA token](https://streamr.network/data-token) is undergoing
                    migration, which started mid-2021 and without a time cap on migrations for the
                    sake of protecting DATA token holder&apos;s funds, the old token still exists
                    as&nbsp;XDATA.
                </FaqMarkdown>
                <FaqMarkdown>
                    You can read more about the [DATA token migration
                    here](https://streamr.network/token-migration/) and in case you still hold
                    XDATA: Read how to migrate XDATA to DATA using the tools and tutorials found on
                    the&nbsp;page.
                </FaqMarkdown>
            </FaqItem>
        </>
    )
}
